import './styles';

import React from 'react';
import PropTypes from 'prop-types';

import Output from './Output';

const DEFAULT_VISIBLE_OUTPUTS_COUNT = 2;

class Outputs extends React.Component {
  static defaultProps = {
    visibleOutputsCount: DEFAULT_VISIBLE_OUTPUTS_COUNT
  };

  static propTypes = {
    outputs: PropTypes.array.isRequired,
    text: PropTypes.string.isRequired,
    showFewerText: PropTypes.string,
    showMoreText: PropTypes.string,
    visibleOutputsCount: PropTypes.number
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };

    this.outputsCount = this.props.outputs.length;
  }

  render() {
    if (!this.outputsCount) return null;

    const visibleOutputs = this.state.visible
      ? this.props.outputs
      : this.props.outputs.slice(0, this.props.visibleOutputsCount);

    return (
      <div className="Outputs">
        <p className="toggle">
          <strong>{this.props.text}</strong>
          {this.outputsCount > this.props.visibleOutputsCount && (
            <span>
              &nbsp;
              <button onClick={this.toggleOutputsVisibility} className="button-unstyled truncated-toggle">
                {this.state.visible ? this.showFewerText : this.showMoreText}
              </button>
            </span>
          )}
          <strong>:</strong>
        </p>
        {visibleOutputs.map((output) => (
          <Output key={`output-${output.id}`} output={output} onBadgeClick={this.onBadgeClick} />
        ))}
      </div>
    );
  }

  get showFewerText() {
    return this.props.showFewerText || I18n.t('Explorer.Mentions.Mention.show_fewer');
  }

  get showMoreText() {
    return (
      this.props.showMoreText ||
      I18n.t('Explorer.Mentions.Mention.cites_more', {
        count: Math.max(this.outputsCount - this.props.visibleOutputsCount, 0)
      })
    );
  }

  toggleOutputsVisibility = () => {
    this.setState({ visible: !this.state.visible });
  };
}

export default Outputs;
