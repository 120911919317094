import './styles';

import { includes, truncate } from 'lodash-es';

import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'util/date';
import Striptags from 'striptags';
import ImageWithFallback from 'components/ImageWithFallback';
import LinkIf from 'components/Explorer/LinkIf';
import Outputs from './Outputs';
import ResearchOutput from 'components/Explorer/models/research_output';

class Post extends React.Component {
  // Setup
  //////////////////////////////////////////////////////////////////////////////

  outputs = [];

  static propTypes = {
    history: PropTypes.object,
    post: PropTypes.object.isRequired,
    first: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.matchingOutputs = props.post.matchingOutputs.map((output) => new ResearchOutput(output));
    this.otherOutputs = props.post.otherOutputs.map((output) => new ResearchOutput(output));
    this.date = dayjs(props.post.postedAt).utc();
  }

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    const formattedDate = this.date.format('YYYY-MM-DDTHH:mm:ssZ');
    const formattedTime = this.date.format('h:mm A UTC');
    const extraClasses = this.props.post.thumbnail ? '' : 'missing-image';

    return (
      <div className={`Mentions-Post ${this.props.post.postType}`}>
        <div className={`post-type-icon ${this.props.post.postType}`}>
          <div className="icon"></div>
        </div>
        <div className="timestamp">
          {this.props.first && <div className="date">{this.date.format('YYYY-MM-DD')}</div>}
          <time dateTime={formattedDate}>{formattedTime}</time>
        </div>
        <div className="content">
          <div className="content-padding">
            <div className="post-content">
              <LinkIf condition={this.hasURL} url={this.props.post.url}>
                <div className={`thumbnail-container ${extraClasses}`}>
                  {this.props.post.thumbnail && <ImageWithFallback src={this.props.post.thumbnail} alt="" />}
                  {this.isRetweet() && (
                    <div className="retweet-badge">
                      <div className="icon" />
                    </div>
                  )}
                </div>
                <div className="inner-content">
                  {this.props.post.title && this.props.post.title.length && (
                    <div className="title">{this.props.post.title}</div>
                  )}
                  <div className="source">{this.sourceDescription}</div>
                  <div className="abstract">
                    {truncate(Striptags(this.props.post.body), {
                      length: 256,
                      separator: /\W/
                    })}
                  </div>
                </div>
              </LinkIf>
            </div>
            {this.matchingOutputs.length > 0 && (
              <div className="output-content">
                <Outputs outputs={this.matchingOutputs} text="Cites the following from your search query" />
              </div>
            )}
            {this.otherOutputs.length > 0 && (
              <div className="output-content">
                <Outputs
                  outputs={this.otherOutputs}
                  text={`Also cites ${this.otherOutputs.length} other output${this.otherOutputs.length > 1 ? 's' : ''}`}
                  showFewerText="[hide]"
                  showMoreText="[show]"
                  visibleOutputsCount={0}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  // Utilities
  //////////////////////////////////////////////////////////////////////////////

  get sourceDescription() {
    return this.sourceDescriptionWithNameAndScreeName || this.sourceDescriptionWithName || this.basicDescription;
  }

  get sourceDescriptionWithNameAndScreeName() {
    const sources = ['tweet', 'rdt', 'bluesky'];
    if (!includes(sources, this.props.post.postType)) return;
    if (!this.hasProfileNameAndScreenName()) return;

    const postType = this.isRetweet() ? 'retweet' : this.props.post.postType;
    return I18n.htmlSafe(postType, {
      scope: 'Explorer.Mentions.type_descriptions',
      screen_name: this.props.post.profileScreenName,
      name: this.props.post.profileName
    });
  }

  get sourceDescriptionWithName() {
    const sources = ['msm', 'blog', 'policy', 'weibo', 'fbwall', 'gplus', 'guideline'];
    if (!includes(sources, this.props.post.postType)) return;
    if (!this.hasProfileName()) return;

    return I18n.htmlSafe(this.props.post.postType, {
      scope: 'Explorer.Mentions.type_descriptions',
      name: this.props.post.profileName
    });
  }

  get basicDescription() {
    const sources = ['peer_review', 'wikipedia', 'linkedin', 'pinterest', 'qna', 'video', 'patent'];
    if (!includes(sources, this.props.post.postType)) return;

    return I18n.htmlSafe(this.props.post.postType, {
      scope: 'Explorer.Mentions.type_descriptions'
    });
  }

  get hasURL() {
    return this.props.post.url && this.props.post.url.length > 0;
  }

  hasProfileNameAndScreenName() {
    return this.hasProfileName() && this.props.post.profileScreenName && this.props.post.profileScreenName.length;
  }

  hasProfileName() {
    return this.props.post.profileName && this.props.post.profileName.length;
  }

  isRetweet() {
    return this.props.post.postType == 'tweet' && !this.props.post.originalPost;
  }
}

export default Post;
