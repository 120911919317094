import { includes, truncate } from 'lodash-es';
import React from 'react';
import PropTypes from 'prop-types';

import config from 'util/site_config.js.erb';
import NumberHelper from 'util/number_helper';
import Query from 'components/Explorer/models/query';
import ResearchOutput from 'components/Explorer/models/research_output';
import { Link } from 'react-router-dom';
import withRouter from 'components/withRouter';
import { defaultSearchScope } from '../../util/default_search_scope';

class ResearchOutputItem extends React.Component {
  query = null;

  state = {
    showToggleAuthors: false,
    collapsedAuthors: true,
    showToggleDepartments: false,
    collapsedDepartments: true,
    showToggleFieldsOfResearch: false,
    collapsedFieldsOfResearch: true,
    showToggleAffiliations: false,
    collapsedAffiliations: true,
    showToggleSustainableDevelopmentGoals: false,
    collapsedSustainableDevelopmentGoals: true,
    showToggleGrants: false,
    collapsedGrants: true
  };

  static propTypes = {
    onBadgeClick: PropTypes.func.isRequired,
    output: PropTypes.instanceOf(ResearchOutput).isRequired,
    history: PropTypes.object
  };

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);
    this.setState({ view: this.query.view });

    this.query.registerCallback(this.refreshView, Query.EVENTS.didChangeView);
  }

  componentWillUnmount() {
    this.query.cleanup();
  }

  render() {
    return (
      <div className="ResearchOutputItem" data-testid="research-output-item">
        <button onClick={this.onClick} className="output">
          <div className="badge">
            {this.badgeImg}
            {this.props.output.verified ? this.verifiedTick : null}
          </div>

          <div className="content">
            <h3 className="title">{this.outputTitle}</h3>

            <div className="meta">
              {this.props.output.meta}
              {this.sortContext}
            </div>
          </div>
        </button>
        {this.affiliation}
      </div>
    );
  }

  refreshView = () => {
    this.setState({
      view: this.query.view
    });
  };

  get badgeImg() {
    return (
      <img
        className="badge"
        loading="lazy"
        src={this.props.output.badgeURL}
        alt={I18n.t('Explorer.Outputs.ResearchOutputItem.alt', {
          score: this.props.output.score
        })}
      />
    );
  }

  get verifiedTick() {
    return (
      <div className="verified-badge" data-testid="verified-badge">
        <div
          className="icon"
          title={I18n.t('Explorer.Outputs.ResearchOutputItem.verified', {
            name: window.current_user.institution_name
          })}
        />
      </div>
    );
  }

  onClick = (event) => {
    event.preventDefault();

    this.props.onBadgeClick(this.props.output);
  };

  get sortContext() {
    if (!this.sourceOrMentionSort) return;

    return (
      <div className="context">
        <span className={`icon ${this.query.sortOrder}`} />
        <span className="count" data-testid="count">
          {NumberHelper.formatNumberWithDelimiter(this.props.output.countForSort)}
        </span>
        <span className="type">
          {I18n.t(this.query.sortOrder, {
            scope: 'Explorer.Outputs.ResearchOutputItem.sort_option_descriptions',
            count: this.props.output.countForSort
          })}
        </span>
      </div>
    );
  }

  get sourceOrMentionSort() {
    return (
      includes(this.sources, this.query.sortOrder) ||
      includes(config.otherSources, this.query.sortOrder) ||
      includes(config.mentionTimeframes, this.query.sortOrder)
    );
  }

  get affiliation() {
    if (this.query.view !== 'list') return;
    if (
      !this.props.output.authors.length &&
      !this.props.output.departments.length &&
      !this.props.output.affiliations.length &&
      !this.props.output.fieldsOfResearch.length &&
      !this.props.output.sustainableDevelopmentGoals.length &&
      !this.props.output.grants.length
    )
      return;

    return (
      <div className="affiliation" data-testid="affiliation">
        {this.authors}
        {this.departments}
        {this.affiliations}
        {this.fieldsOfResearch}
        {this.sustainableDevelopmentGoals}
        {this.grants}
      </div>
    );
  }

  affiliationSearchLink(filters) {
    const scopeFilter = { scope: this.query.filters.scope || defaultSearchScope(window.current_user) };
    return this.query.withFilters({ ...filters, ...scopeFilter }).location;
  }

  get authors() {
    if (this.query.view !== 'list') return;
    if (!this.props.output.authors.length) return;

    return (
      <div className="authors">
        <span className="section-title">
          {I18n.htmlSafe('Explorer.Outputs.ResearchOutputItem.authors_html', {
            count: this.props.output.authors.length
          })}
          {this.toggle('Authors')}
        </span>
        <div ref={this.displayAuthorsToggle} className={this.state.collapsedAuthors ? 'chips' : 'chips expanded'}>
          {this.props.output.authors.map((author) => (
            <Link key={author.id} to={this.affiliationSearchLink({ author_id: author.id })} className="chip">
              {author.name}
            </Link>
          ))}
        </div>
      </div>
    );
  }

  get departments() {
    if (this.query.view !== 'list') return;
    if (!this.props.output.departments.length) return;

    return (
      <div className="departments">
        <span className="section-title">
          {I18n.htmlSafe('Explorer.Outputs.ResearchOutputItem.departments_html', {
            count: this.props.output.departments.length
          })}
          {this.toggle('Departments')}
        </span>
        <div
          ref={this.displayDepartmentsToggle}
          className={this.state.collapsedDepartments ? 'chips' : 'chips expanded'}
        >
          {this.props.output.departments.map((department) => (
            <Link
              key={department.id}
              to={this.affiliationSearchLink({ department_id: department.id })}
              className="chip"
            >
              {department.name}
            </Link>
          ))}
        </div>
      </div>
    );
  }

  get affiliations() {
    if (window.current_user.librarian) return;
    if (this.query.view !== 'list') return;
    if (!this.props.output.affiliations.length) return;

    return (
      <div className="grid-affiliations">
        <span className="section-title">
          {I18n.htmlSafe('Explorer.Outputs.ResearchOutputItem.grid_affiliation_html', {
            count: this.props.output.affiliations.length
          })}
          {this.toggle('Affiliations')}
        </span>
        <div
          ref={this.displayAffiliationsToggle}
          className={this.state.collapsedAffiliations ? 'chips' : 'chips expanded'}
        >
          {this.props.output.affiliations.map((affiliation) => (
            <Link
              key={affiliation.grid_id}
              to={this.affiliationSearchLink({ 'affiliations[]': [affiliation.grid_id] })}
              className="chip"
            >
              {affiliation.name}
            </Link>
          ))}
        </div>
      </div>
    );
  }

  get fieldsOfResearch() {
    if (window.current_user.librarian) return;
    if (this.query.view !== 'list') return;
    if (!this.props.output.fieldsOfResearch.length) return;

    return (
      <div className="fields-of-research">
        <span className="section-title">
          {I18n.htmlSafe('Explorer.Outputs.ResearchOutputItem.fields_of_research_html', {
            count: this.props.output.fieldsOfResearch.length
          })}
          {this.toggle('FieldsOfResearch')}
        </span>
        <div
          ref={this.displayFieldsOfResearchToggle}
          className={this.state.collapsedFieldsOfResearch ? 'chips' : 'chips expanded'}
        >
          {this.props.output.fieldsOfResearch.map((fieldsOfResearch) => (
            <Link
              key={fieldsOfResearch.code}
              to={this.affiliationSearchLink({ 'field_of_research_codes[]': [fieldsOfResearch.code] })}
              className="chip"
            >{`${fieldsOfResearch.code} ${fieldsOfResearch.name}`}</Link>
          ))}
        </div>
      </div>
    );
  }

  get sustainableDevelopmentGoals() {
    if (window.current_user.librarian) return;
    if (this.query.view !== 'list') return;
    if (!this.props.output.sustainableDevelopmentGoals.length) return;

    return (
      <div className="sustainable-development-goals">
        <span className="section-title">
          {I18n.htmlSafe('Explorer.Outputs.ResearchOutputItem.sustainable_development_goals_html', {
            count: this.props.output.sustainableDevelopmentGoals.length
          })}
          {this.toggle('SustainableDevelopmentGoals')}
        </span>
        <div
          ref={this.displaySustainableDevelopmentGoalsToggle}
          className={this.state.collapsedSustainableDevelopmentGoals ? 'chips' : 'chips expanded'}
        >
          {this.props.output.sustainableDevelopmentGoals.map((sustainableDevelopmentGoal) => (
            <Link
              key={sustainableDevelopmentGoal.code}
              to={this.affiliationSearchLink({ 'sustainable_development_goals[]': [sustainableDevelopmentGoal.code] })}
              className="chip"
            >
              {`${sustainableDevelopmentGoal.code} ${sustainableDevelopmentGoal.name}`}
            </Link>
          ))}
        </div>
      </div>
    );
  }

  get grants() {
    if (window.current_user.librarian) return;
    if (this.query.view !== 'list') return;
    if (!this.props.output.grants.length) return;

    return (
      <div className="grants">
        <span className="section-title">
          {I18n.htmlSafe('Explorer.Outputs.ResearchOutputItem.grants_html', {
            count: this.props.output.grants.length
          })}
          {this.toggle('Grants')}
        </span>
        <div ref={this.displayGrantsToggle} className={this.state.collapsedGrants ? 'chips' : 'chips expanded'}>
          {this.props.output.grants.map((grant) => (
            <Link
              key={grant.dimensions_grant_id}
              to={this.affiliationSearchLink({ 'grant_id[]': [grant.dimensions_grant_id] })}
              className="chip"
            >
              {grant.project_numbers}
            </Link>
          ))}
        </div>
      </div>
    );
  }

  toggle(stateAttr) {
    if (!this.state[`showToggle${stateAttr}`]) return;

    return (
      // eslint-disable-next-line -- jsx-a11y [TODO]
      <span
        className="truncated-toggle"
        onClick={() =>
          this.setState({
            [`collapsed${stateAttr}`]: !this.state[`collapsed${stateAttr}`]
          })
        }
      >
        {I18n.t(`Explorer.Outputs.ResearchOutputItem.show_${this.state[`collapsed${stateAttr}`] ? 'more' : 'less'}`)}
      </span>
    );
  }

  displayAuthorsToggle = (el) => {
    this.setState({
      showToggleAuthors: this.props.output.authors.length && this.collapsableElement(el)
    });
  };

  displayDepartmentsToggle = (el) => {
    this.setState({
      showToggleDepartments: this.props.output.departments.length && this.collapsableElement(el)
    });
  };

  displayFieldsOfResearchToggle = (el) => {
    this.setState({
      showToggleFieldsOfResearch: this.props.output.fieldsOfResearch.length && this.collapsableElement(el)
    });
  };

  displayAffiliationsToggle = (el) => {
    this.setState({
      showToggleAffiliations: this.props.output.affiliations.length && this.collapsableElement(el)
    });
  };

  displaySustainableDevelopmentGoalsToggle = (el) => {
    this.setState({
      showToggleSustainableDevelopmentGoals:
        this.props.output.sustainableDevelopmentGoals.length && this.collapsableElement(el)
    });
  };

  displayGrantsToggle = (el) => {
    this.setState({
      showToggleGrants: this.props.output.grants.length && this.collapsableElement(el)
    });
  };

  collapsableElement(el) {
    if (!el) return false;

    return el.scrollHeight > el.clientHeight;
  }

  get outputTitle() {
    return truncate(this.props.output.title, {
      length: this.outputTitleLength,
      separator: /\W/,
      omission: '…'
    });
  }

  get outputTitleLength() {
    if (this.query.view === 'grid') {
      return 80;
    }

    if (this.props.output.departments.length > 0 || this.props.output.authors.length > 0) {
      return 100;
    }

    return 250;
  }

  get sources() {
    return [...config.sources];
  }
}

export default withRouter(ResearchOutputItem);
