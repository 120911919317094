import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import withRouter from 'components/withRouter';
import { each } from 'lodash-es';
import dayjs from 'util/date';

import AutocompleteFieldConfig from 'components/Explorer/models/autocomplete_field_config';
import Query from 'components/Explorer/models/query';
import FilterSet from 'components/Explorer/models/filter_set';
import MentionSourcesFilter from '../MentionSourcesFilter';
import { CountriesField, DateField } from 'components/Explorer/Form';
import { timeframeMinimumDate } from '../../util/timeframe_minimum_date';

import config from 'util/site_config.js.erb';

class FilterPanel extends React.Component {
  // Setup
  //////////////////////////////////////////////////////////////////////////////

  static propTypes = {
    history: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      maximumDate: '9999-12-31',
      minimumDate: '0001-01-01',
      typeOptions: {}
    };
  }

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);
    this.query.registerCallback(this.setupFilterSet, Query.EVENTS.didChangeFilters);
    this.query.registerCallback(this.handleNewMentionSourceOrder, Query.EVENTS.didChangeMentionSourceOrder);
    this.setupFilterSet();
  }

  componentWillUnmount() {
    this.query.cleanup();
  }

  // Rendering
  //////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <div className="Explorer-Form Explorer-MentionSources-FilterPanel">
        <div className="Explorer-MentionSources-FilterPanel-row">
          <MentionSourcesFilter
            sourcesTypes={this.typeOptions}
            filterSet={this.state.filterSet}
            queryFilter="mentionSourcesTypesForFilter"
            dropdownSourcesType="mention_source_type"
            autocompleteFieldConfig={this.autocompleteFieldConfig}
            assignRef={this.storeMentionSourcesField}
          />
        </div>
        <div className="Explorer-MentionSources-FilterPanel-row">
          <div className="Explorer-MentionSources-FilterPanel-date-range">
            {this.mentionedAfter}
            {this.mentionedBefore}
          </div>
          <CountriesField
            filterSet={this.state.filterSet}
            fieldID="mention_sources_countries"
            fieldName="mention_sources_countries[]"
            filterSetParam="mention_sources_countries[]"
            label={I18n.t('Explorer.MentionSources.FilterPanel.fields.countries.label')}
            placeholder={I18n.t('Explorer.Form.CountriesField.placeholder')}
            onChangeCallback={this.trackCountriesSearch}
            ref={this.storeCountriesField}
          />

          {this.buttons}
        </div>
        <div
          className={classNames('Explorer-MentionSources-FilterPanel-warning-message', {
            'show-error': this.state.hasError
          })}
        >
          {this.state.errorMessage}
        </div>
      </div>
    );
  }

  onMentionedAfterChange = (dateText) => {
    this.setState({
      minimumDate: dateText
    });
    this.trackMentionTimeSearch;
  };

  get mentionedAfter() {
    return (
      <div className="Explorer-FilterPanel-field">
        <DateField
          fieldID="mention_sources_mentioned_after"
          fieldName="mention_sources_mentioned_after"
          label={I18n.t('Explorer.MentionSources.FilterPanel.fields.mentioned_after.title')}
          filterSet={this.state.filterSet}
          placeholder={I18n.t('Explorer.MentionSources.FilterPanel.fields.mentioned_after.placeholder')}
          onChangeCallback={this.onMentionedAfterChange}
          minimumSelectableDate={timeframeMinimumDate(this.state.filterSet.get('timeframe'))}
          maximumDate={this.state.maximumDate}
          ref={this.storeMentionedAfterField}
        />
      </div>
    );
  }

  onMentionedBeforeChange = (dateText) => {
    this.setState({
      maximumDate: dateText
    });
    this.trackMentionTimeSearch;
  };

  get mentionedBefore() {
    return (
      <div className="Explorer-FilterPanel-field">
        <DateField
          fieldID="mention_sources_mentioned_before"
          fieldName="mention_sources_mentioned_before"
          label={I18n.t('Explorer.MentionSources.FilterPanel.fields.mentioned_before.title')}
          filterSet={this.state.filterSet}
          placeholder={I18n.t('Explorer.MentionSources.FilterPanel.fields.mentioned_before.placeholder')}
          onChangeCallback={this.onMentionedBeforeChange}
          minimumDate={this.state.minimumDate}
          ref={this.storeMentionedBeforeField}
        />
      </div>
    );
  }

  get buttons() {
    return (
      <div className="Explorer-FilterPanel-buttons">
        <button className="apply-button" onClick={this.applySearch}>
          {I18n.t('Explorer.MentionSources.FilterPanel.buttons.apply')}
        </button>
        <button className="clear-button" onClick={this.resetSearch}>
          {I18n.t('Explorer.MentionSources.FilterPanel.buttons.reset')}
        </button>
      </div>
    );
  }

  get autocompleteFieldConfig() {
    let config = new AutocompleteFieldConfig();
    config.id = 'mention_sources_types';
    config.name = 'mention_sources_types[]';
    config.type = 'mention_sources_types';
    config.placeholder = I18n.t('Explorer.MentionSources.FilterPanel.fields.mention_sources_types.placeholder');

    return config;
  }

  // Actions
  //////////////////////////////////////////////////////////////////////////////

  storeMentionSourcesField = (field) => {
    this.mentionSourcesField = field;
  };

  storeCountriesField = (field) => {
    this.countriesField = field;
  };

  storeMentionedBeforeField = (field) => {
    this.mentionedBeforeField = field;
  };

  storeMentionedAfterField = (field) => {
    this.mentionedAfterField = field;
  };

  setupFilterSet = () => {
    this.setState(
      {
        filterSet: new FilterSet(this.query.filters)
      },
      this.clearAutocompleteFilters
    );
  };

  get typeOptions() {
    const options = {};
    each(
      config.mentionSourcesTypes,
      (type) =>
        (options[type] = I18n.t(type, {
          scope: 'Explorer.MentionSources.FilterPanel.type',
          default: type
        }))
    );

    return options;
  }

  clearSearch = () => {
    if (this.mentionSourcesField) this.mentionSourcesField.clear();
    if (this.mentionedBeforeField) this.mentionedBeforeField.clear();
    if (this.mentionedAfterField) this.mentionedAfterField.clear();
    if (this.countriesField) this.countriesField.clear();
  };

  resetSearch = () => {
    this.clearSearch();
    this.applySearch();
  };

  applySearch = () => {
    this.setState({ hasError: false });

    if (this.areDatesValid()) {
      Analytics.trackEvent('apply-mention-author-filter');

      // Ordering by followers makes sense for twitter only, so change it if it does not make sense
      if (
        this.query.mentionSourceOrder === 'followers_desc' &&
        this.state.filterSet.get('mention_sources_types[]') !== 'type:tw'
      ) {
        this.query.mentionSourceOrder = Query.DEFAULT_MENTION_SOURCE_ORDER;
      }

      const newLocation = this.query.withFilters(this.state.filterSet.populatedFilters).location;

      if (newLocation.search !== window.location.search.replace('?', '')) {
        this.props.history.push(newLocation);
      }
    } else {
      this.setState({
        hasError: true,
        errorMessage: I18n.t('Explorer.MentionSources.FilterPanel.errors.invalid_dates')
      });
    }
  };

  areDatesValid() {
    const fromDate = this.mentionedAfterField.value;
    const toDate = this.mentionedBeforeField.value;

    if (fromDate === '' && toDate === '') return true;
    if (!(fromDate && toDate)) return false;

    return dayjs(toDate).diff(fromDate, 'year', true) <= 1.0;
  }

  trackCountriesSearch() {
    Analytics.trackEvent('filter-mention-author-country');
  }

  trackTypeSearch(source) {
    Analytics.trackEvent('filter-mention-author-source', { source });
  }

  trackMentionTimeSearch() {
    Analytics.trackEvent('filter-mention-author-time');
  }

  clearAutocompleteFilters = () => {
    if (this.countriesField && this.state.filterSet.get('mention_sources_countries[]').length == 0) {
      this.countriesField.clearSelected();
    }
    if (this.mentionSourcesField && this.state.filterSet.get('mention_sources_types[]').length == 0) {
      this.mentionSourcesField.clearSelected();
    }
  };

  handleNewMentionSourceOrder = () => {
    if (this.query.mentionSourceOrder == 'followers_desc') {
      this.mentionSourcesField.overrideSelectedMentionSourcesFromTypes(['tw']);
    }
  };
}

export default withRouter(FilterPanel);
