import React from 'react';
import PropTypes from 'prop-types';

class Tabs extends React.PureComponent {
  static TYPES = ['tweet', 'fbwall', 'msm', 'policy', 'guideline'];

  static propTypes = {
    selectedSource: PropTypes.oneOf(Tabs.TYPES).isRequired,
    onChange: PropTypes.func.isRequired
  };

  render() {
    return (
      <div className="Explorer-Demographics-Tabs">
        <nav
          className="Explorer-Demographics-Tabs-wrapper"
          aria-label={I18n.t('Explorer.accessibility.DemographicsTabs')}
        >
          {Tabs.TYPES.map((t) => this.tab(t))}
        </nav>
      </div>
    );
  }

  tab(type) {
    const classNames = ['tab', type];
    if (this.props.selectedSource === type) classNames.push('active');

    return (
      <div key={type} className="Explorer-Demographics-Tab-Wrapper">
        <button className={classNames.join(' ')} key={type} onClick={() => this.props.onChange(type)}>
          <span className="title">{I18n.t(`Explorer.Demographics.types.${type}.title`)}</span>
          <span className="meta">{I18n.t(`Explorer.Demographics.types.${type}.meta`)}</span>
        </button>
      </div>
    );
  }
}

export default Tabs;
