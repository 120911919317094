import './styles';

import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import withLocation from 'components/withLocation';
import withRouter from 'components/withRouter';

import Query from 'components/Explorer/models/query';

const tabs = ['highlights', 'outputs', 'timeline', 'demographics', 'mentions', 'mention_sources', 'journals'];

class TabBar extends React.Component {
  static propTypes = {
    history: PropTypes.object
  };

  UNSAFE_componentWillMount() {
    this.query = new Query(this.props.history);
  }

  componentWillUnmount() {
    this.query.cleanup();
  }

  render() {
    return (
      <nav className="Explorer-TabBar" aria-label={I18n.t('Explorer.accessibility.TabBar')}>
        {tabs
          .filter((tab) => tab != 'mention_sources' || window.current_user.mention_sources_access)
          .map((tab) => (
            <NavLink key={tab} to={this.query.locationWithPathname(`/${tab}`)} activeClassName="active">
              {I18n.t(tab, { scope: 'Explorer.TabBar' })}
            </NavLink>
          ))}
      </nav>
    );
  }
}

export default withRouter(withLocation(TabBar));
