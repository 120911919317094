import React from 'react';
import PropTypes from 'prop-types';

import Tabs from './Tabs';
import NumberHelper from 'util/number_helper';

import Export from 'components/Explorer/Export';
import SaveSearch from 'components/Explorer/SaveSearch';
import SummaryBlock from 'components/Explorer/ContentPanel/SummaryBlock';

import helpFor from 'util/help.js';

class Summary extends React.PureComponent {
  static propTypes = {
    selectedSource: PropTypes.oneOf(Tabs.TYPES).isRequired,
    data: PropTypes.shape({
      posts: PropTypes.number.isRequired,
      profiles: PropTypes.number.isRequired,
      countries: PropTypes.number.isRequired
    }).isRequired
  };

  render() {
    return (
      <SummaryBlock variant="alt">
        <div>
          <span className="part">{this.part('posts')} </span>
          <span className="part">{this.part('profiles')} </span>
          <span className="part">{this.part('countries')} </span>
        </div>

        <Export endpoint="demographics" resultsCount={this.props.data.posts} help={helpFor('demographics')} />
        <SaveSearch endpoint="demographics" analyticsName="Demographics" />
      </SummaryBlock>
    );
  }

  part(name) {
    return I18n.htmlSafe(`Explorer.Demographics.types.${this.props.selectedSource}.counts.${name}`, {
      count: this.props.data[name],
      formattedCount: NumberHelper.formatNumberWithDelimiter(this.props.data[name])
    });
  }
}

export default Summary;
